import React, { useState, useEffect } from 'react';
import logo from '../../resources/images/logotipo-preto.png';
import Axios from 'axios';

export default function PedidosInterna() {
  const [pedido, setPedido] = useState([]);
  const [shop, setShop] = useState();
  const [tempo, setTempo] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const timer = () => setTempo(tempo - 1);
  const getParamFromUrl = (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };

  const createInterval = () => {
    setTempo(30);
  }

  const getStatus = async () => {
    try {
      const id = getParamFromUrl('id');
      const retorno = await Axios.get(`order/status?order=${id}`);
      if(retorno.data.status !== pedido.status) {
        loadContent();
      } else {
        createInterval()
      }
    } catch(err) {
      console.log(err);
      console.log(err.response);
    }
  }

  const loadContent = async () => {
    try {
      const id = getParamFromUrl('id');
      const shopkeeper = getParamFromUrl('shopkeeper');
      const data = await Axios.get(`order/cart-open?shopkeeper=${shopkeeper}&order_id=${id}`);
      console.log(data);
      setPedido(data.data);
      setShop(data.data.shopkeeper);
      setProdutos(data.data.items);
      createInterval()
    } catch(err) {
      console.log(err);
      console.log(err.response);
    }
  }
  
  useEffect(() => {
    loadContent();
  }, []);

  useEffect(
    () => {
      if (tempo <= 0) {
        getStatus();
        return;
      }
      // console.log(tempo);
      const id = setInterval(timer, 1000);
      return () => clearInterval(id);
    },
    [tempo]
  );
  return (
    <div className="content pedidosInterna">
      <div className="title centerTitle">
        Seu QR code
      </div>
      <div className="qrcodeArea">
        <div className="qrcode_area">
          <div className="item">
            <div dangerouslySetInnerHTML={{ __html: pedido && pedido.qrcode }} />
          </div>
        </div>
        {/* <div className="localSenha">
          <div className="localSenhaInfo">
            <div className="text mb_text">
              Local: {shop && shop.name}
            </div>
          </div>
        </div> */}
        <div className="text">
          {pedido.validade && (
            <span>
              Validade: {pedido && pedido.validade}
            </span>
          )}
        </div>
      </div>
      <div className="timeline">
        {/* adicionar active-one deixa selecionado */}
        <div className="one active-one">
          <div className="timelineText">
            Recebido
          </div>
        </div>
        {/* adicionar active-two deixa selecionado */}
        <div className="two active-two">
          <div className="timelineText">
            {pedido.status === '1' && ('Aguardando pagamento')}
            {pedido.status === '2' && ('Pagamento em análise')}
            {pedido.status === '3' && (
              <span className="confirmado">Pagamento confirmado</span>
            )}
            {pedido.status === '4' && ('Em produção')}
            {pedido.status === '5' && ('Realizado')}
            {pedido.status === '6' && ('Parcial')}
            {pedido.status === '7' && ('Finalizado')}
            {pedido.status === '8' && (
              <span className="cancelado">Negado</span>
            )}
            {pedido.status === '9' && (
              <span className="cancelado">Cancelado</span>
            )}
            {pedido.status === '10' && ('Expirado')}
            {pedido.status === '11' && ('Reembolsado')}
            {pedido.status === '12' && ('Reembolsado')}
          </div>
        </div>
        {/* adicionar active-three deixa selecionado */}
        <div className={pedido.status === '5' || pedido.status === '7' ? 'three active-three' : 'three'}>
          {pedido.status === '5' || pedido.status === '7' ? (
            <div className="timelineText">
              <span className="confirmado">
                Pronto
              </span>
            </div>
          ) : (
            <div className="timelineText">
              <span className="confirmado">
                <div className="oculto">
                  Fim
                </div>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="entregaArea">
        <div className="title">
          Entrega:
        </div>
        <div className="title rightTitle">
        {pedido && pedido.entrega}
        </div>
      </div>
      <div className="localSenha">
        <div className="localSenhaInfo">
            <div className="imageArea">
            {shop && shop.mv_shopkeepers_image && (
              <div className="image" style={{backgroundImage: "url(" + shop.mv_shopkeepers_image + ")"}}></div>
            )}
            </div>
            {shop && (
              <div className="text">
                {shop.mv_shopkeepers_name ? shop.mv_shopkeepers_name : shop.mv_shopkeepers_legal_name}
              </div>
            )}
        </div>
        {/* <div className="text">
          Senha 0215
        </div> */}
      </div>
      <div className="totalDetalhe">
        <div className="title leftTitle">
          Total: 
        </div>
        <div className="title rightTitle">
          R$ {pedido && pedido.total_price_br}
        </div>
      </div>
      <div className="title">
        Produtos
      </div>
      <div className="productsList">
        <ul>
          {produtos.map((prod, index) => (
          <li key={index}>
            <div className="produtoArea">
              <img src={prod.image ? prod.image : logo} alt="Produto" />
            </div>
            <div className="dadoProduto">
              <div className="nomeProduto">
                {prod.name}
              </div>
              <div className="quantidadeArea">
                <div className="quantidade">
                  {prod.amount}
                </div>
              </div>
            </div>
            <div className="valorProduto">
              R$ {prod.price_br}
            </div>
          </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
