import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './app.global.scss';
import Menu from './pages/menu';
import Qrcode from './pages/qrcode';
import Intro from './pages/intro';
import Home from './pages/home';
import BackQrcode from './pages/home/backqrcode';
import NotiticationNotation from './pages/notifications/button';
import Notitications from './pages/notifications/index';
import PedidosLista from './pages/pedidos/index';
import PedidosInterna from './pages/pedidos/intern';
import LojaHome from './pages/lojas/index';
import LojaLanches from './pages/lojas/listLanche';
import DestaquesList from './pages/lojas/destaquesList';
import DetailProduct from './pages/lojas/detailProduct';
import Carrinho from './pages/carrinho';
import Cupom from './pages/carrinho/cupom';
import Login from './pages/login';
import Cadastro from './pages/cadastro';
import Token from './pages/login/token';
import Card from './pages/card';
import Success from './pages/card/paymentSuccess';
import CardAdd from './pages/card/cardAdd';
import UserCode from './pages/user/qrcode';
import Perfil from './pages/perfil';
import PerfilEdit from './pages/perfil/editar';
import PerfilCard from './pages/perfil/cartao';
import PerfilCardAdd from './pages/perfil/cartaoAdd';
import axios from 'axios';

export default function App() {
  const [logado, setLogado] = useState(false);

  const loadContent = async () => {
    if(localStorage.getItem('user') !== '{}') {
      let user = JSON.parse(localStorage.getItem('user'));
      if(user) {
        axios.defaults.headers.common['Authorization'] = user.api_token;
        setLogado(user);
      }
    } else {
      setLogado(false);
    }
  };
  useEffect(() => {
    loadContent();
  }, []);
  return (
    <Router>
      <div className="App">
        <div className="menuArea">
          <Menu />
        </div>
        <div className="contentArea">
          <Switch>
            {logado && (
            <Route path="/perfil/cartao/adicionar">
              <PerfilCardAdd />
            </Route>
            )}
            {logado && (
            <Route path="/perfil/cartao">
              <PerfilCard />
            </Route>
            )}
            {logado && (
            <Route path="/perfil/editar">
              <PerfilEdit />
            </Route>
            )}
            {logado && (
            <Route path="/perfil">
              <Perfil />
            </Route>
            )}
            {logado && (
            <Route path="/user/qrcode">
              <UserCode />
            </Route>
            )}
            {logado && (
            <Route path="/pagamento/sucesso">
              <Success />
            </Route>
            )}
            {logado && (
            <Route path="/login/cartao/add">
              <CardAdd />
            </Route>
            )}
            {logado && (
            <Route path="/carrinho/cartao">
              <Card />
            </Route>
            )}
            <Route path="/cadastro/token">
              <Token />
            </Route>
            <Route path="/carrinho/cupom">
              <Cupom />
            </Route>
            {logado && (
            <Route path="/carrinho">
              <Carrinho />
            </Route>
            )}
            <Route path="/login">
              <Login refreshUser={loadContent} />
            </Route>
            <Route path="/cadastro">
              <Cadastro />
            </Route>
            <Route path="/loja/add">
              <DetailProduct />
            </Route>
            <Route path="/loja/destaques">
              <DestaquesList />
            </Route>
            <Route path="/loja/produtos">
              <LojaLanches />
            </Route>
            <Route path="/loja/detalhes">
              <LojaHome />
            </Route>
            {logado && (
            <Route path="/pedidos/interna">
              <PedidosInterna />
            </Route>
            )}
            {logado && (
            <Route path="/pedidos">
              <PedidosLista />
            </Route>
            )}
            <Route path="/notificacoes">
              <Notitications />
            </Route>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/intro">
              <Intro />
            </Route>
            <Route path="/">
              <Qrcode />
            </Route>
          </Switch>
        </div>
        <div className="notificacoes">
          <NotiticationNotation />
        </div>
        <div className="backQrcode">
          <BackQrcode />
        </div>
      </div>
    </Router>
  );
}