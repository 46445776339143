import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PerfilUser from '../../resources/images/menu/perfil.png';
import MaskedInput from 'react-text-mask';
import Axios from 'axios';

export default function PerfilEditar() {
  const refInput = useRef();
  const { current } = refInput;
  const [user, setUser] = useState({});
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [imageUser, setImageUser] = useState(false);
  const history = useHistory();

  const getUserData = () => {
    let user = JSON.parse(localStorage.getItem('user'))
    user.password = '';
    return user;
  };

  const selectImage = async () => {
    current.click();
  }

  const getBase64 = async (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let imageUser = reader.result
      const image = JSON.stringify({"imagem": imageUser});
      try {
      var data = JSON.stringify({"imagem": image});
      var config = {
        method: 'post',
        url: 'https://api.yuzer.com.br/api/perfil/alterarfoto',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      Axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        console.log(response.data);
        localStorage.setItem('user', JSON.stringify(response.data));
        alert('Imagem atualizada!')
        history.push("/perfil");
      })
      .catch(function (error) {
        console.log(error);
      });

      
      // Axios({
      // method: 'post',
      // url: 'perfil/alterarfoto/',
      // headers: { 
      //   'Content-Type': 'application/json'
      // },
      // data: image,
      // })
      // .then(function (response) {
      //   //handle success
      //   console.log(response.data);
      //   localStorage.setItem('user', JSON.stringify(response.data));
      //   alert('Imagem atualizada!')
      //   history.push("/perfil");
      // })
      // .catch(function (response) {
      //   //handle error
      //   console.log(response);
      // });

      setLoading(false);
      } catch (err) {
        console.log(err);
        alert('Ops, ocorreu um erro ao atualizar sua foto, por favor tente novamente.');
        setLoading(false);
        console.log(err.response);
      }
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
  }

  const updateImage = async(e) => {
    e.preventDefault()
    if (e.target.files[0]){
      try {
        // const upload = new FormData();
        // const imagedata = e.target.files[0];
        // upload.append('image', imagedata);
        let result = await getBase64(e.target.files[0]);
        
        // history.push("/perfil");

        // console.log(data);
        // localStorage.setItem('user', JSON.stringify(data));
        // alert('Imagem atualizada!')
        // history.push("/perfil");
        // setLoading(false);
      } catch (err) {
        console.log(err);
        alert('Ops, ocorreu um erro ao atualizar sua foto, por favor tente novamente.');
        setLoading(false);
        console.log(err.response);
      }
    }
  }

  const getEstados = async () => {
    let {data} = await Axios.get('states');
    setEstados(data);

    return data;
  }

  const getCidades = async (state) => {
    let {data} = await Axios.get('uf-cities/uf/'+state);
    setCidades(data);

    return data;
  }

  const phoneMask = (input) => {
    if (input && input.length >= 14) {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    } else {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }
  }
  const cpf = (input) => {
    return [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-',/\d/,/\d/]
  }

  const loadContent = async () => {
    const data = getUserData();
    setForm(data);
    setUser(data);
    let states = await getEstados();
    await getCidades(states.filter((a) => a.uf_initials === data.state)[0].uf_code)
  }

  useEffect(() => {
    loadContent();

  }, []);

  const send = async (e) => {
    e.preventDefault();
    setLoading(true);
    let phone_unmask = form.phone;
    try {
      if(form.name === '') {
        alert('Preencha seu nome')
        setLoading(false);
        return false
      }
      
      if(form.email === '') {
        alert('Preencha seu e-mail')
        setLoading(false);
        return false
      }

      if(form.phone === '') {
        alert('Preencha seu telefone')
        setLoading(false);
        return false
      } else {
        phone_unmask = parseInt(form.phone.replace(/[^0-9]/g, ''));
        phone_unmask = ''+phone_unmask+'';
      }

      if (form.password) {
        if(form.password.length < 6) {
          alert('A senha precisa ter no mínimo 6 caracteres!')
          setLoading(false);
          return false
        }
        
        if(form.password !== form.passwordRepeat) {
          alert('O campo senha e repita a senha devem ser iguais!')
          setLoading(false);
          return false
        }
      }
 
      let payload = {
        status: 1,
        id: user.id,
        name: form.name,
        cpf: form.cpf,
        email: form.email,
        phone: phone_unmask,
        state: estados.filter((a) => a.uf_initials === form.state)[0].uf_initials,
        city: form.city,
        zip_code: form.zipcode,
        street: form.street,
        street_number: form.street_number,
        neighborhood: form.neighborhood,
      }

      if (form.password) {
        payload.password = form.password;
      }

      if (form.image) {
        payload.image = form.image;
      }

      const { data } = await Axios.post('customers/update/'+user.id, payload)
      localStorage.setItem('user', JSON.stringify(data));
      setLoading(false);
      alert('Sucesso! Seus dados foram atualizados.');

    } catch (err) {
      console.log(err);
      alert('Ops, ocorreu um erro ao efetuar o cadastro, por favor tente novamente.');
      setLoading(false);
      console.log(err.response);
    }
  };

  return (
    <div className="perfilAreaUser">
      <div className="perfilEditar content">
        <div className="editImageArea">
          <div className="menuPerfilArea">
            <div className="menuPerfil" style={{backgroundImage: `url(${user.image ? user.image : PerfilUser})`}}>
            </div>
          </div>
          <div className="editText" onClick={() => selectImage()}>
            Trocar foto
          </div>
          <input type="file" id="file" name="image"
          onChange={(b) => updateImage(b)} ref={refInput} style={{display: "none"}}/>
        </div>
      </div>
      <div className="formArea content">
        <form onSubmit={send}>
          <div className="ui-input-title">
            Seu nome
          </div>
          <input
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            className="ui-input" 
            placeholder="Nome" 
            type="text" 
            required 
          />
          <div className="ui-input-title">
            Seu melhor e-mail
          </div>
          <input
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
            className="ui-input" 
            placeholder="E-mail" 
            type="email" 
            required 
          />
          <div className="ui-input-title">
            Seu telefone
          </div>
          <MaskedInput
            value={form.phone}
            onChange={(e) => setForm({ ...form, phone: e.target.value })}
            mask={phoneMask}
            className="ui-input"
            placeholder="Telefone"
            guide={false}
          />
          <div className="ui-input-title">
            Seu CPF
          </div>
          <MaskedInput
            value={form.cpf}
            onChange={(e) => setForm({ ...form, cpf: e.target.value })}
            mask={cpf}
            className="ui-input"
            placeholder="CPF"
            guide={false}
          />
          <div className="ui-input-title">
            Seu Estado
          </div>
          <select 
            value={form.state}
            onChange={(e) => {
              setForm({ ...form, state: e.target.value})
              getCidades(estados.filter((a) => a.uf_initials === e.target.value)[0].uf_code)
            }}
            className="ui-input">
            <option value="" disabled>Selecione um estado</option>
            {estados.map((item, index) => (
              <option key={index} value={item.uf_initials}>{item.uf_initials}</option>
            ))}
          </select>
          <div className="ui-input-title">
            Sua Cidade
          </div>
          <select
            value={form.city}
            onChange={(e) => setForm({ ...form, city: e.target.value })}
            className="ui-input">
            <option value="" disabled>Selecione uma cidade</option>
            {cidades.map((item, index) => (
              <option key={index} value={item.city_name}>{item.city_name}</option>
            ))}
          </select>
          <div className="ui-input-title">
            Seu bairro
          </div>
          <input
            value={form.neighborhood}
            onChange={(e) => setForm({ ...form, neighborhood: e.target.value })}
            className="ui-input" 
            placeholder="Bairro" 
            type="text" 
            required 
          />
          <div className="ui-input-title">
            Seu endereço
          </div>
          <input
            value={form.street}
            onChange={(e) => setForm({ ...form, street: e.target.value })}
            className="ui-input" 
            placeholder="Endereço" 
            type="text" 
            required 
          />
          <div className="ui-input-title">
            Numero do endereço
          </div>
          <input
            value={form.street_number}
            onChange={(e) => setForm({ ...form, street_number: e.target.value })}
            className="ui-input" 
            placeholder="Número" 
            type="text" 
            required 
          />
          <div className="ui-input-title">
            Sua senha
          </div>
          <input
            value={form.password}
            onChange={(e) => setForm({ ...form, password: e.target.value })}
            className="ui-input" 
            placeholder="Preencha caso deseja alterar" 
            type="password" 
          />
          <div className="ui-input-title">
            Repita sua senha
          </div>
          <input
            value={form.passwordRepeat}
            onChange={(e) => setForm({ ...form, passwordRepeat: e.target.value })}
            className="ui-input" 
            placeholder="Repita a senha" 
            type="password" 
          />
          <button type="submit" className="attButton" disabled={loading}>
            Atualizar
          </button>
        </form>
      </div>
    </div>
  )
}
