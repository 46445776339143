import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
// import facebook from '../../resources/images/facebook.png';
import axios from 'axios';
import MaskedInput from 'react-text-mask';
// import FacebookLogin from 'react-facebook-login';
import OneSignal from 'react-onesignal';
export default function Login() {
  const formLogin = useRef(null);
  const [loading, setLoading] = useState(false);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const history = useHistory();
  const [login, setLogin] = useState({
    name: '',
    email: '',
    telefone: '',
    password: '',
    state: '',
    city: '',
    cpf: '',
    passwordRepeat: '',
    zipcode: '',
    street: '',
    neighborhood: '',
    street_number: ''
  });

  const getEstados = async () => {
    let {data} = await axios.get('states');
    setEstados(data);
  }

  const getCidades = async (state) => {
    let resultado = estados.filter((item) => item.uf_initials === state);
    if(resultado.length) {
      let {data} = await axios.get('uf-cities/uf/'+resultado[0].uf_code);
      setCidades(data);
      return data
    }
    return []
  }

  const getFacebookData = () => {
    const user = JSON.parse(localStorage.getItem('userFacebook'))
    
    setLogin({
      ...login,
      ...user
    })
  };

  const phone = (input) => {
    if (input && input.length >= 14) {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    } else {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }
  }
  const cpf = (input) => {
    return [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-',/\d/,/\d/]
  }
  const cep = (input) => {
    return [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
  }

  const verificaCpf = (cpf) =>{
    cpf = cpf.replace(/\D/g, '');
    if(cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    var result = true;
    [9,10].forEach(function(j){
        var soma = 0, r;
        cpf.split(/(?=)/).splice(0,j).forEach(function(e, i){
            soma += parseInt(e) * ((j+2)-(i+1));
        });
        r = soma % 11;
        r = (r <2)?0:11-r;
        if(r != cpf.substring(j, j+1)) result = false;
    });
    return result;
  }
  const checkCep = async (e) => {
    if(e.target.value.length >= 9) {
      let cepPreenchido = e.target.value;
      try {
        let retorno = await axios.get('https://viacep.com.br/ws/'+ e.target.value + '/json');
        let estado = '';
        let noEstado = false;
        switch (retorno.data.uf) {
          case 'AC':
            estado = 'Acre';
          break;
          case 'AL':
            estado = 'Alagoas';
          break;
          case 'AP':
            estado = 'Amapá';
          break;
          case 'AM':
            estado = 'Amazonas';
          break;
          case 'BA':
            estado = 'Bahia';
          break;
          case 'CE':
            estado = 'Ceará';
          break;
          case 'DF':
            estado = 'Distrito Federal';
          break;
          case 'ES':
            estado = 'Espírito Santo';
          break;
          case 'GO':
            estado = 'Goiás';
          break;
          case 'MA':
            estado = 'Maranhão';
          break;
          case 'MT':
            estado = 'Mato Grosso';
          break;
          case 'MS':
            estado = 'Mato Grosso do Sul';
          break;
          case 'MG':
            estado = 'Minas Gerais';
          break;
          case 'PA':
            estado = 'Pará';
          break;
          case 'PB':
            estado = 'Paraíba';
          break;
          case 'PR':
            estado = 'Paraná';
          break;
          case 'PE':
            estado = 'Pernambuco';
          break;
          case 'PI':
            estado = 'Piauí';
          break;
          case 'RJ':
            estado = 'Rio de Janeiro';
          break;
          case 'RN':
            estado = 'Rio Grande do Norte';
          break;
          case 'RS':
            estado = 'Rio Grande do Sul';
          break;
          case 'RO':
            estado = 'Rondônia';
          break;
          case 'RR':
            estado = 'Roraima';
          break;
          case 'SC':
            estado = 'Santa Catarina';
          break;
          case 'SP':
            estado = 'São Paulo';
          break;
          case 'SE':
            estado = 'Sergipe';
          break;
          case 'TO':
            estado = 'Tocantins';
          break
          default:
            noEstado = true;
        }
        if(!noEstado) {
          let cities = await getCidades(estado);
          let retornoCities = '';
          if(cities.length) {
            retornoCities = cities.filter((item) => item.city_name === retorno.data.localidade);
          }
          setLogin({...login,
            state: estado,
            city: retornoCities.length ? retornoCities[0].city_name : '',
            zipcode: retorno.data.cep,
            street: retorno.data.logradouro,
            neighborhood: retorno.data.bairro
          })
        } else {
          setLogin({...login,
            zipcode: cepPreenchido
          })
        }
      } catch(err) {
        setLogin({...login,
          zipcode: cepPreenchido
        })
        console.log(err);
      }
    }
  }
  const logar = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let phone_unmask = login.telefone;
      if(login.password.length < 6) {
        alert('A senha precisa ter no mínimo 6 caracteres!')
        setLoading(false);
        return false
      }
      if(login.password !== login.passwordRepeat) {
        alert('O campo senha e repita a senha devem ser iguais!')
        setLoading(false);
        return false
      }
      if(login.name === '') {
        alert('Preencha seu nome')
        setLoading(false);
        return false
      }
      if(login.email === '') {
        alert('Preencha seu e-mail')
        setLoading(false);
        return false
      }
      if(login.telefone === '') {
        alert('Preencha seu telefone')
        setLoading(false);
        return false
      } else {
        phone_unmask = parseInt(login.telefone.replace(/[^0-9]/g, ''));
        phone_unmask = ''+phone_unmask+'';
      }

      if(login.cpf) {
        let retorno = await verificaCpf(login.cpf)
        if(!retorno) {
          alert('Cpf inválido!')
          setLoading(false);
          return false
        }
      }
      
      // estado: estados.filter((item) => item.uf_initials === login.state)[0].uf_code
      let send = {
        status: 1,
        name: login.name,
        cpf: login.cpf,
        email: login.email,
        phone: phone_unmask,
        state: login.state,
        city: login.city,
        zip_code: login.zipcode,
        street: login.street,
        street_number: login.street_number,
        neighborhood: login.neighborhood,
        password: login.password,
      }
      
      if (login.facebook_id) {
        send.facebook_id = login.facebook_id;
      }

      if (login.image) {
        send.image = login.image;
      }

      const { data } = await axios.post('customers/create', send)

      localStorage.setItem('userDados', JSON.stringify(data));
      axios.defaults.headers.common['Authorization'] = data.api_token;
      const options = {allowLocalhostAsSecureOrigin: true, requiresUserPrivacyConsent: true}
      OneSignal.initialize("23934880-54a9-40cd-9e43-ba34b7169dce", options);
      OneSignal.sendTag("customer", data.id);
      // window.location.href = "/cadastro/token";
      history.push("/cadastro/token");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert('Ops, ocorreu um erro ao efetuar o cadastro, o e-mail ou outro dado pessoal já foi utilizado!');
      console.log(err);
    }
  };

  const loadContent = async () => {
    await getEstados();
    getFacebookData();
  };
  
  useEffect(() => {
    loadContent();
  }, []);
  return (
    <div className="content login">
      <div className="loginTitle">
        Faça seu cadastro
      </div>
      <div className="loginText">
        Queremos te conhecer! Para isso precisamos de algumas informações
      </div>
      <form ref={formLogin} onSubmit={logar}>
        <div className="ui-input-title">
          Seu nome
        </div>
        <input
          value={login.name}
          onChange={(e) => setLogin({ ...login, name: e.target.value })}
          className="ui-input" 
          placeholder="Nome" 
          type="text" 
          required 
        />
        <div className="ui-input-title">
          Seu melhor e-mail
        </div>
        <input
          value={login.email}
          onChange={(e) => setLogin({ ...login, email: e.target.value })}
          className="ui-input" 
          placeholder="E-mail" 
          type="email" 
          required 
        />
        <div className="ui-input-title">
          Seu telefone
        </div>
        <MaskedInput
          value={login.telefone}
          onChange={(e) => setLogin({ ...login, telefone: e.target.value })}
          mask={phone}
          className="ui-input"
          placeholder="Telefone"
          guide={false}
        />
        <div className="ui-input-title">
          Seu CPF
        </div>
        <MaskedInput
          value={login.cpf}
          onChange={(e) => setLogin({ ...login, cpf: e.target.value })}
          mask={cpf}
          className="ui-input"
          placeholder="CPF"
          guide={false}
        />
        <div className="ui-input-title">
          Seu CEP
        </div>
        <MaskedInput
          value={login.zipcode}
          onChange={(e) => checkCep(e)}
          mask={cep}
          className="ui-input"
          placeholder="CEP"
          guide={false}
        />
        <div className="ui-input-title">
          Seu Estado
        </div>
        <select 
          value={login.state}
          onChange={(e) => {
            setLogin({ ...login, state: e.target.value})
            getCidades(e.target.value)
          }}
          className="ui-input">
          <option value="" disabled>Selecione um estado</option>
          {estados.map((item, index) => (
            <option key={index} value={item.uf_initials}>{item.uf_initials}</option>
          ))}
        </select>
        <div className="ui-input-title">
          Sua Cidade
        </div>
        <select
          value={login.city}
          onChange={(e) => setLogin({ ...login, city: e.target.value })}
          className="ui-input">
          <option value="" disabled>Selecione uma cidade</option>
          {cidades.map((item, index) => (
            <option key={index} value={item.city_name}>{item.city_name}</option>
          ))}
        </select>
        <div className="ui-input-title">
          Seu bairro
        </div>
        <input
          value={login.neighborhood}
          onChange={(e) => setLogin({ ...login, neighborhood: e.target.value })}
          className="ui-input" 
          placeholder="Bairro" 
          type="text" 
          required 
        />
        <div className="ui-input-title">
          Seu endereço
        </div>
        <input
          value={login.street}
          onChange={(e) => setLogin({ ...login, street: e.target.value })}
          className="ui-input" 
          placeholder="Endereço" 
          type="text" 
          required 
        />
        <div className="ui-input-title">
          Numero do endereço
        </div>
        <input
          value={login.street_number}
          onChange={(e) => setLogin({ ...login, street_number: e.target.value })}
          className="ui-input" 
          placeholder="Número" 
          type="text" 
          required 
        />
        <div className="ui-input-title">
          Sua senha
        </div>
        <input
          value={login.password}
          onChange={(e) => setLogin({ ...login, password: e.target.value })}
          className="ui-input" 
          placeholder="Senha" 
          type="password" 
          required 
        />
        <div className="ui-input-title">
          Repita sua senha
        </div>
        <input
          value={login.passwordRepeat}
          onChange={(e) => setLogin({ ...login, passwordRepeat: e.target.value })}
          className="ui-input" 
          placeholder="Repita a senha" 
          type="password" 
          required 
        />
        <div className="buttonArea">
          <div className="hasAccount">
            <Link to="/login">
              Já tenho conta
            </Link>
          </div>
          <button type="submit" disabled={loading}>
            Cadastrar
          </button>
        </div>
      </form>
      {/* <div className="otherOption">
        Ou entre com
      </div> */}
      {/* <div className="loginButtons">
        <button type="button" className="facebook">
          <img src={facebook} alt="Facebook" />
          Facebook
        </button>
      </div> */}
    </div>
  )
}
