import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
// import card from '../../resources/images/bandeiras/mastercard.png';
import mastercard from '../../resources/images/bandeiras/mastercard.png';
import visa from '../../resources/images/bandeiras/visa.jpg';
import hipercard from '../../resources/images/bandeiras/hipercard.jpg';
import elo from '../../resources/images/bandeiras/elo.jpg';
import amex from '../../resources/images/bandeiras/amex.jpg';
import aura from '../../resources/images/bandeiras/aura.jpg';
import discover from '../../resources/images/bandeiras/discover.jpg';
import jcb from '../../resources/images/bandeiras/jcb.jpg';
import newCard from '../../resources/images/novoCartao.jpg';
import MaskedInput from 'react-text-mask';
import axios from 'axios';

export default function Card() {
  const [check, setCheck] = useState(null);
  const [sending, setSending] = useState(false);
  const [cartao, setCartao] = useState(null);
  const [pay, setPay] = useState(0);
  const [cards, setCards] = useState([]);
  const [cvv, setCvv] = useState(null);
  const history = useHistory();

  const mask = (input) => {
    return [/[0-9]/, /\d/,  /\d/,  /\d/,  /\d/]
  }

  const getCards = async () => {
    let {data} = await axios.get('order/card-list');
    if(data === 'Nenhum cartão encontrado!') {
      setCards([]);
    } else {
      setCards(data);
    }
  }
  const selecionaCartao = async (index, id) => {
    setCartao(id);
    setCheck(index);
  }
  
  const sendPedido = async () => {
    setSending(true);
    console.log(localStorage.getItem('pedido_id'));
    if(!localStorage.getItem('pedido_id')) {
      alert('Ops! pedido não encontrado.');
      window.location.href = "/";
    }
    try {
      let itens = {
        order_id: localStorage.getItem('pedido_id'),
        card_id: cartao,
        cvv: cvv,
      }
      console.log(itens);
      let data = await axios.post('order/create', itens);
      localStorage.setItem('status_pedido', JSON.stringify(data));
      setSending(false);
      if(data.status === 200) {
        // window.location.href = "/pagamento/sucesso";
        history.push("/pagamento/sucesso")
      }
      if(data.status === 201) {
        // window.location.href = "/pagamento/sucesso";
        history.push("/pagamento/sucesso")
      }
      if(data.status === 202) {
        alert('Carrinho vazio ou pagamento já gerado!');
        window.location.href = "/";
      }
      if(data.status === 203) {
        alert('Cartão de crédito ou CVV inválido!');
      }
      if(data.status === 206) {
        alert(data.data.errors);
        window.location.href = "/";
      }
      if(data.status === 406) {
        alert('Erro ao processar pagamento, por favor tente novamente.');
      }
    } catch (err) {
      setSending(false);
      alert('Ops, '+err);
      console.log(err);
    }
  }

  const loadContent = async () => {
    await getCards();
  };
  
  useEffect(() => {
    loadContent();
  }, []);

  return (
    <div className="content cartao">
      {cards && cards.length > 0 ? (
        <div className="cartaoList">
          <ul>
            {cards && cards.map((item,index) => (
            <li key={index}>
              <div 
                className={check === index ? 'cardSelected selected' : 'cardSelected'} 
                onClick={() => selecionaCartao(index, item.id_customer_card)}>
                <div className="infoLeft uppercase_card">
                  {item.customer_card_flag === 'mastercard' && (
                    <img src={mastercard} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'visa' && (
                    <img src={visa} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'hipercard' && (
                    <img src={hipercard} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'elo' && (
                    <img src={elo} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'amex' && (
                    <img src={amex} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'aura' && (
                    <img src={aura} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'discover' && (
                    <img src={discover} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'jcb' && (
                    <img src={jcb} alt="cartão" />
                  )}
                  {item.customer_card_flag}
                  <div className="number">
                    {item.customer_card_numbers}
                  </div>
                </div>
                <div className="selectArea">
                  <div className={check === index ? 'selectAreaActive' : ''}></div>
                </div>
              </div>
            </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="novoCartao">
          <img src={newCard} alt="Novo Cartão" />
          <div className="newText">
            Você ainda não tem cartões cadastrados.
          </div>
          <div className="newTextBold">
            Cadastre seu cartão para continuar a compra.
          </div>
        </div>
      )}
      {cards && cards.length > 0 && (
      <div className="other">
        <Link to="/perfil/cartao/adicionar?return=pedido">
          <button>
            {cards && cards.length > 0 ? 'Adicionar outro' : 'Adicionar novo'}
          </button>
        </Link>
      </div>
      )}
      {cards && cards.length > 0 ? (
      <div className={pay === 0 ? 'proximoArea' : 'hide'}>
        <button type="button" onClick={() => setPay(1)} disabled={check === null}>
          Pagar
        </button>
      </div>
      ) : (
        <div className="buttonFixed buttonPagamento">
          <Link to="/perfil/cartao/adicionar?return=pedido">
            <button type="button">
            Adicionar novo Cartão
            </button>
          </Link>
        </div>
      )}
      {/* div que fica por cima de tudo que pode ser fechada ao clicar */}
      <div className={pay === 1 ? 'activeBG' : 'hide'} onClick={() => setPay(0)}></div>
      <div className={pay === 1 ? 'modalPay' : 'modalPay hide'}>
        <div className={pay === 1 ? 'cvvArea' : 'hidecvv'}>
          <div className="cvvTitle">
            CVV
          </div>
          <div className="inputArea">
            <MaskedInput
              mask={mask}
              className="ui-input"
              guide={false}
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
            />
            <div className="inputLead">
              Código que fica atrás do cartão em 3 dígitos
            </div>
          </div>
          <div className="buttonPay">
            <button type="button" onClick={() => sendPedido()} disabled={sending}>
              Pagar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
