import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
// import LogoMenu from '../../resources/images/menu/logo.png';
import Sacola from '../../resources/images/menu/menuicone.png';
import Perfil from '../../resources/images/menu/perfil.png';
import voltar from '../../resources/images/voltar.png';
import logo from '../../resources/images/logotipo-preto.png';
export default function Menu() {
  const location = useLocation();
  const history = useHistory();
  const [category, setCategory] = useState(false);
  const [user, setUser] = useState({});
  const [store, setStore] = useState({});

  const getUserData = () => {
    if(localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if(user) {
        return user
      }
    } else {
      return {}
    }
  };
  
  const getStoreData = () => {
    console.log(localStorage.getItem('store'));
    if(localStorage.getItem('store')) {
      return JSON.parse(localStorage.getItem('store'))
    }
  };

  const goBack = async () => {
    history.goBack()
  };

  const getParamFromUrl = (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };

  const redirectMenu = async () => {
    window.location.href = '/login';
  }

  const loadContent = async () => {
    try {
      setUser(getUserData());
      setStore(getStoreData());
      if(getParamFromUrl('category_name')) {
        setCategory(getParamFromUrl('category_name'));
      } else {
        setCategory('Produtos')
      }
    } catch(err) {
      console.log(err);
      console.log(err.response);
    }
  }
  
  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
    loadContent();
  }, [window.location.href]);
  return (
    <div className={location.pathname === '/perfil/editar' ? 'menuFull menuFullWhite' : 'menuFull'}>
      {location.pathname !== '/pagamento/sucesso' && 
      location.pathname !== '/teste' && (
        <>
          {location.pathname === '/home' || location.pathname === '/' ? (
            <div className="menu">
              {location.pathname !== '/' ? (
              <div>
                <Link to={'/home'}>
                  {/* <img className="logoArea" src={LogoMenu} alt="logo" /> */}
                  {localStorage.getItem('logo_mv') ? (
                    <img className="logoArea" src={localStorage.getItem('logo_mv')} alt="logo" />
                  ) : (
                    <img className="logoArea" src={logo} alt="logo" />
                  )}
                </Link>
              </div>
              ) : (
                <div></div>
              )}
              <div className="menuFullArea">
                {localStorage.getItem('user') && localStorage.getItem('user') !== 'null' && localStorage.getItem('user') !== '{}' ? (
                  <>
                  {location.pathname !== '/' && (
                    <Link to={'/carrinho?shopkeeper='+localStorage.getItem('shopkeeper')}>
                      <div className="menuSacola" style={{backgroundImage: `url(${Sacola})`}}>
                      </div>
                    </Link>
                  )}
                  <Link to={'/perfil'}>
                    <div className="menuPerfilArea">
                      <div className="menuPerfil" style={{backgroundImage: `url(${user.image ? user.image : Perfil})`}}>
                      </div>
                    </div>
                  </Link>
                  </>
                ) : (
                  <>
                  <div onClick={() => redirectMenu()}>
                    <div className="menuSacola" style={{backgroundImage: `url(${Sacola})`}}>
                    </div>
                  </div>
                  <div onClick={() => redirectMenu()}>
                    <div className="menuPerfilArea">
                      <div className="menuPerfil" style={{backgroundImage: `url(${user.image ? user.image : Perfil})`}}>
                      </div>
                    </div>
                  </div>
                  </>
                )}
              </div>        
            </div>
          ) : (
            location.pathname !== '/loja/detalhes' && location.pathname !== '/carrinho' ? ( 
            <div className="menuBackArea">
              {location.pathname === '/pedidos/interna' ? (
                <Link className="menuBack" to={`/pedidos`}>
                  <img src={voltar} alt="voltar" />
                </Link>
              ) : (
              location.pathname !== '/pedidos' ? (
                <div className={location.pathname === '/perfil/editar' ? 'menuBackWhite' : 'menuBack'} onClick={()=> goBack()}>
                  <img src={voltar} alt="voltar" />
                </div>
              ) : (
                <Link className="menuBack" to={`/loja/detalhes?qrcode=${localStorage.getItem('code_qr')}&id_mv_shopkeepers=${localStorage.getItem('mv_shopkeeper')}`}>
                  <img src={voltar} alt="voltar" />
                </Link>
              ))}
                <div className={location.pathname === '/perfil/editar' ? 'titleAreraWhite' : 'titleArera'}>
                  {location.pathname === '/notificacoes' && ('Notificações')}
                  {location.pathname === '/pedidos' && ('Meus Pedidos')}
                  {location.pathname === '/pedidos/interna' && ('Pedido')}
                  {location.pathname === '/loja/produtos' && (category)}
                  {location.pathname === '/loja/bebidas' && ('Bebidas')}
                  {location.pathname === '/loja/destaques' && ('Combos e Promoções')}
                  {location.pathname === '/loja/sobremesas' && ('Sobremesas')}
                  {location.pathname === '/loja/add' && ('Detalhes do item')}
                  {location.pathname === '/carrinho' && ('Meu pedido')}
                  {location.pathname === '/carrinho/cupom' && ('Cupom')}
                  {location.pathname === '/login/token' && ('Cadastro')}
                  {location.pathname === '/carrinho/cartao' && ('Pagamento')}
                  {location.pathname === '/user/qrcode' && ('Seu QR Code')}
                  {location.pathname === '/login/cartao/add' && ('Cartão de crédito')}
                  {location.pathname === '/perfil' && ('Perfil')}
                  {location.pathname === '/perfil/editar' && ('Perfil')}
                  {location.pathname === '/perfil/cartao' && ('Pagamentos')}
                  {location.pathname === '/perfil/cartao/adicionar' && ('Adicionar cartão')}
                  {location.pathname === '/perfil/cartao/editar' && ('Editar cartão')}
                </div>
            </div>
            ) : (
              <div className="menuLoja">
                <div className="menuBack">
                {location.pathname === '/carrinho' && localStorage.getItem('mv_shopkeeper') ? (
                  <Link to={`/loja/detalhes?qrcode=${localStorage.getItem('code_qr')}&id_mv_shopkeepers=${localStorage.getItem('mv_shopkeeper')}`}>
                    <img src={voltar} alt="voltar" />
                  </Link>
                ) : (
                  location.pathname === '/loja/detalhes' ? (
                    localStorage.getItem('noback') === '1' ? (
                      null
                    ) : (
                      <Link to={`/?qrcode=${localStorage.getItem('code_qr')}`}>
                        <img src={voltar} alt="voltar" />
                      </Link>
                    )
                  ) : (
                    <div className="menuBack" onClick={()=> goBack()}>
                      <img src={voltar} alt="voltar" />
                    </div>
                  )
                )}
                </div>
                {location.pathname === '/carrinho' ? (
                <div className="titleArera">
                  Carrinho
                </div>
                ) : (
                  <div className="titleArera title_logo left_title">
                    {/* {store.logotipo ? (
                      <img src={store.logotipo} alt="Loja" className="logo_loja" />
                    ) : (
                      <img src={store.mv_shopkeepers_image} alt="Loja" className="logo_loja" />
                    )} */}
                    {store.nome_loja || store.name}
                  </div>
                )}
                <div className="menuFullArea">
                  {localStorage.getItem('user') && localStorage.getItem('user') !== 'null' && localStorage.getItem('user') !== '{}' ? (
                    <>
                    <Link to={'/carrinho?shopkeeper='+localStorage.getItem('shopkeeper')}>
                      <div className="menuSacola" style={{backgroundImage: `url(${Sacola})`}}>
                      </div>
                    </Link>
                    <Link to={'/perfil'}>
                      <div className="menuPerfilArea">
                        <div className="menuPerfil" style={{backgroundImage: `url(${user.image ? user.image : Perfil})`}}>
                        </div>
                      </div>
                    </Link>
                    </>
                  ) : (
                    <>
                    <Link to={'/login'}>
                      <div className="menuSacola" style={{backgroundImage: `url(${Sacola})`}}>
                      </div>
                    </Link>
                    <Link to={'/login'}>
                      <div className="menuPerfilArea">
                        <div className="menuPerfil" style={{backgroundImage: `url(${user.image ? user.image : Perfil})`}}>
                        </div>
                      </div>
                    </Link>
                    </>
                  )}
                </div> 
              </div>
            )
          )}
        </>
      )}
    </div>
  )
}
