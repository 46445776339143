import React, { useState, useEffect } from 'react';
import Axios from 'axios';
// import { useHistory } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import todas from '../../resources/images/home/todas.png';
import logo from '../../resources/images/logotipo-preto.png';
import Search from '../../resources/images/menu/search.png';

export default function Home() {
  const [qrcode, setQrcode] = useState('');
  const [activeCategorie, setActiveCategorie] = useState(false);
  const [categories, setCategories] = useState([]);
  const [stores, setStores] = useState([]);
  const [storesFiltered, setStoresFiltered] = useState([]);
  const [search, setSearch] = useState('');
  // const history = useHistory();

  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 50
    }
  };

  const getParamFromUrl = (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };

  const loadContent = async () => {
    try {
      const qrcode = getParamFromUrl('qrcode');

      const { data } = await Axios.get(`webapp/list-products?qrcode=${qrcode}`);
      console.log(data);
      
      setQrcode(qrcode);
      setCategories(data.categories);
      setStores(data.shopkeepers);
      setStoresFiltered(data.shopkeepers);
      console.log(data.shopkeepers);
    } catch(err) {
      console.log(err);
      console.log(err.response);
    }
  }

  const filterByCategory = (index) => {
    setActiveCategorie(index)

    if (index === false) {
      return setStoresFiltered(stores);
    }

    return setStoresFiltered(
      stores.filter(({ id_category }) => id_category == categories[index].id)
    )
  }

  const searchStore = (e) => {
    e.preventDefault();

    return setStoresFiltered(
      stores.filter((item)=> item.nome_loja.toLowerCase().match(search.toLocaleLowerCase()))
    )
  }

  const goToDetails = async (item) => {
    localStorage.setItem('store', JSON.stringify(item));
    // history.push(`/loja/detalhes?qrcode=${qrcode}&id_mv_shopkeepers=${item.id_mv_shopkeepers}`)
    window.location.href= `/loja/detalhes?qrcode=${qrcode}&id_mv_shopkeepers=${item.id_mv_shopkeepers}`;
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <>
      <div className="menuSearch">
        <form onSubmit={searchStore}>
          <input type="search" placeholder="Pesquise aqui..." onChange={(e) => setSearch(e.target.value)} />
          <button type="submit">
            <img src={Search} alt="busca" />
          </button>
        </form>
      </div>  
      <div className="homeArea">
        <div className="homeTitle">
          Categorias
        </div>
        <div className="homeAreaCategorias">
          <ul>
            <li 
              onClick={() => filterByCategory(false)} 
              className={activeCategorie === false ? 'active' : ''}>
              <div className="imagem">
                <img src={todas} alt="categorias" />
              </div>
              <div className="name">Todas</div>
            </li>
            {categories.map((item, index) => (
              <li 
                key={index} 
                onClick={() => filterByCategory(index)} 
                className={activeCategorie === index ? 'active' : ''}>
                <div className="imagem">
                  <img src={item.image ? item.image : logo} alt="categorias" />
                </div>
                <div className="name">{item.name}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="lojasArea">
          <div className="homeTitle">
            Lojas
          </div>
          <div className="lojasAreaList">
            <ul>
              {/* className="disabled" */}
              {storesFiltered.map((item, index) => (
                <li key={index} onClick={() => goToDetails(item)}>
                  <div className="imageBox">
                    <div className="image" style={{backgroundImage: "url(" + (item.logotipo ? item.logotipo : logo) + ")"}}>
                      {/* <div className="status_loja">
                        Abre às 18:30
                      </div> */}
                    </div>
                  </div>
                  <div className="lojaNome">{item.nome_loja}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
