import React, { useEffect, useState } from 'react';
import { useHistory, Redirect, Link } from 'react-router-dom';
// import produto from '../../resources/images/productDetail.jpg';
import close from '../../resources/images/close.png';
import more from '../../resources/images/more.png';
import less from '../../resources/images/less.png';
import logo from '../../resources/images/logotipo-preto.png';
import Axios from 'axios';

export default function DetailProduct() {
  const [product, setProduct] = useState([]);
  // const [gocart, setGoCart] = useState(null);
  const [observacao, setObservacao] = useState({
    description: '',
  });
  const [observacoes, setObservacoes] = useState([]);
  const [quantidade, setQuantidade] = useState(1);
  const [valor, setValor] = useState(null);
  const history = useHistory();

  const diminuiQuantidade = async () => {
    if(quantidade === 1) {
      setQuantidade(1)
      setarValor(1)
    } else {
      setQuantidade(quantidade-1)
      setarValor(quantidade-1)
    }
  };
  const aumentaQuantidade = async () => {
    if(quantidade >= product.quantity) {
      alert('Você não pode pedir mais que '+product.quantity+' itens')
      return false
    }
    setQuantidade(quantidade+1)
    setarValor(quantidade+1)
  };

  const addObservation = async (e) => {
    try {
      e.preventDefault();
      setObservacoes([...observacoes, observacao.description]);
      setObservacao({description: ''});
    } catch (err) {
      alert('Ops, ocorreu um erro ao adicionar esta observação, por favor tente mais tarde.');
      console.log(err);
    }
  }

  const removeItem = (a) => {
    let itens = observacoes;
    itens.splice(a, 1);
    setObservacoes([...itens]);
  }
  
  const sendProduct = async () => {
    try {
      if(localStorage.getItem('user') === '{}' || !localStorage.getItem('user')) {
        const id_produto = getParamFromUrl('product_id');
        history.push("/login?product="+id_produto)
        // return <Redirect to={"/login?product="+id_produto} />
        // window.location.href = "/login?product="+id_produto;
        return false
      }
      let atualizar = {
        id_stock_list_product: product.id_stock_list_product,
        action: 1,
        shopkeeper: localStorage.getItem('shopkeeper'),
        mv_shopkeeper: localStorage.getItem('mv_shopkeeper'),
        qrcode: localStorage.getItem('code_qr'),
        amount: quantidade,
        observation: observacoes.map((item) => (
          {text: item}
        ))
      }
      let {data} = await Axios.post('order/cart', atualizar);
      console.log(data);
      let shopkeep = localStorage.getItem('shopkeeper');
      // setGoCart(true)
      history.push("/carrinho?shopkeeper="+shopkeep)
      // window.location.href = "/carrinho?shopkeeper="+shopkeep;

    } catch (err) {
      alert('Ops! '+err);
      console.log(err);
    }
  }

  const setarValor = async (qnt) => {
    let preco_produto = product.promotional_price ? product.promotional_price : product.price;
    let valor = (preco_produto*qnt).toFixed(2).replace('.',',');
    setValor(valor);
  }

  const getParamFromUrl = (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };

  const loadContent = async () => {
    try {
      const productId = getParamFromUrl('product_id');
      
      let url = `webapp/product?id=${productId}`;

      const { data } = await Axios.get(url);
      console.log(data);
      setProduct(data);
      if(data.promotional_price) {
        setValor(data.promotional_price_br);
      } else {
        setValor(data.price_br);
      }
    } catch(err) {
      console.log(err);
      console.log(err.response);
    }
  }
  
  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
  }, [observacoes]);

  // if(gocart){
  //   return <Redirect push to={'/carrinho?shopkeeper='+localStorage.getItem('shopkeeper')} />
  // }

  return (
    <div className="content detalheProduto">
      <div className="productImage">
        <img src={product.image ? product.image : logo} alt="produto" />
      </div>
      <div className="detalheProdutoInfo">
        <div className="title">
          {product.name}
        </div>
        {product.descricao !== null && (
          <div className="description">
            {product.descricao}
          </div>
        )}
        <div className="productValor">
          {product.promotional_price ? (
            'De R$ '+product.price_br+' por R$ '+product.promotional_price_br
          ) : (
            'R$ '+product.price_br
          )}
        </div>
      </div>
      <div className="addInfo">
        <form onSubmit={addObservation}>
          <input 
          value={observacao.description}
          onChange={(e) => setObservacao({ ...observacao, description: e.target.value })}
          required 
          type="text" 
          placeholder="Adicione uma observação" />
          <button type="submit">
            Adicionar
          </button>
        </form>
        <div className="observacoes">
          <ul>
            {observacoes.length > 0 && observacoes.map((item, index) => (
            <li key={index}>
              <div className="texto">
                {item}
              </div>
              <div className="button" onClick={() => removeItem(index)}>
                <img src={close} alt="Remover" />
              </div>
            </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="addProduct">
        <div className="moreLess">
          <div className="less" onClick={() => diminuiQuantidade()}>
            <img src={less} alt="menos" />
          </div>
          <div className="quant">
            {quantidade}
          </div>
          <div className="more" onClick={() => aumentaQuantidade()}>
            <img src={more} alt="Mais" />
          </div>
        </div>
        <div className="addInfoProduct" onClick={() => sendProduct()}>
          <div className="addTitle">
            Adicionar
          </div>
          <div className="productValue">
            {valor && (
              'R$ '+valor
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
