import React, { useState } from 'react';
import cupomImage from '../../resources/images/cupom.png';

export default function Cupom() {
  const [cupom, setCupom] = useState(0);
  return (
    <div className="content cupom">
      <div className="cupomAdd">
        <form>
          <input required type="text" placeholder="Código de cupom" />
          <button type="submit">
            Adicionar
          </button>
        </form>
      </div>
      <div className="cupomNot" onClick={() => setCupom(0)}>
        <div className="cupomNotTitle">
          Sem cupom
        </div>
        <div className="selectArea">
          <div className={cupom === 0 ? 'selectAreaActive' : ''}></div>
        </div>
      </div>
      <div className="cupomLista">
        <ul>
          {[1,2,3].map((index) => (
            <li key={index} onClick={() => setCupom(index)}>
              <div className="cupomListaTopo">
                <div className="nome">
                  <img src={cupomImage} alt="cupom" />
                  Nome do cupom
                </div>
                <div className="selectArea">
                  <div className={cupom === index ? 'selectAreaActive' : ''}></div>
                </div>
              </div>
              <div className="text">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
              </div>
              <div className="termina">
                Termina em 05:21
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
