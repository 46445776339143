import React from 'react';
import mcdonalds from '../../resources/images/mcdonalds.png';
export default function Notifications() {

  return (
    <div className="content notifications">
      <ul>
        {[1,2,3,4,5,6].map((index) => (
          <li key={index}>
            <div className="imageArea">
              <div className="image" style={{backgroundImage: "url(" + mcdonalds + ")"}}></div>
            </div>
            <div className="text">
              Uau! O seu <b>pedido</b> no MC Donald's já está <b>pronto.</b> É só apresentar o seu <b>QR Code</b> ao pegá-lo. - 13:33
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
