import React, { useEffect, useRef } from 'react';
import QrReader from 'react-qr-reader';
// import { createHashHistory } from "history";
// import { useHistory } from 'react-router-dom';
import axios from 'axios';
export default function Qrcode() {
  const refScan = useRef();
  const { current } = refScan;
  // const history = useHistory();
  // const [value, setValue] = useState(null);
  // const history = createHashHistory();
  const handleError = err => {
    console.error(err)
  }
  const handleScan = async data => {
    if(data) {
      try {
        let qr = data.split('=');
        let qrcodeUrl = qr[1];
        if(!qr[1]) {
          qrcodeUrl = data;
        }
        let retorno = await axios.get('webapp/list-products?qrcode='+qrcodeUrl);
        let log = await axios.get('webapp/qrcode?qrcode='+qrcodeUrl);
        console.log(log);
        if(retorno.data.type === 'mv') {
          localStorage.setItem('logo_mv', retorno.data.mv.image);
          localStorage.setItem('code_qr', qrcodeUrl);
          if(current) {
            current.stopCamera();
          }

          window.location.href = `/home?qrcode=${qrcodeUrl}`;
          // history.push(`/home?qrcode=${qrcodeUrl}`);
        }
        if(retorno.data.type === 'shopkeeper') {
          localStorage.setItem('mv_shopkeeper', retorno.data.shopkeeper.id_mv_shopkeepers);
          localStorage.setItem('shopkeeper', retorno.data.shopkeeper.id);
          localStorage.setItem('code_qr', qrcodeUrl);
          console.log(retorno.data);
          localStorage.setItem('store', JSON.stringify(retorno.data.shopkeeper));
          localStorage.setItem('noback', '1');
          if(current) {
            current.stopCamera();
          }

          window.location.href = `/loja/detalhes?qrcode=${qrcodeUrl}&id_mv_shopkeepers=${retorno.data.shopkeeper.id_mv_shopkeepers}`;
          // history.push(`/loja/detalhes?qrcode=${qrcodeUrl}&id_mv_shopkeepers=${retorno.data.shopkeeper.id_mv_shopkeepers}`);
        }
      } catch (err) {
        console.log(err);
        console.log(err.response.data);
        alert(err.response.data);
        // alert('Ops, houve um problema na leitura do QrCode');
      }
    }
  }
  
  const getParamFromUrl = (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };
  useEffect(
    () => {
      // localStorage.clear();
      // localStorage.removeItem('mv_shopkeeper');
      // localStorage.removeItem('logo_mv');
      // localStorage.removeItem('code_qr');
      // localStorage.removeItem('store');
      localStorage.removeItem('shopkeeper');
      localStorage.removeItem('noback');
      const param = getParamFromUrl('qrcode');
      if(param) {
        handleScan(param);
      }
    },
    []
  );
  return (
    <div className="content qrcode">
      <div className="title uppercase">
        Escaneie e compre
      </div>
      <QrReader
        ref={refScan}
        className="qrcodeReader"
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
      />
      {/* {value !== null && (
        <div className="estabelecimento">Estabelecimento: {value}</div>
      )} */}
      <div className="version">v 1.0.13</div>
    </div>
  )
}
