import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PerfilUser from '../../resources/images/menu/perfil.png';
import rightWhite from '../../resources/images/rightWhiteSeta.png';
import right from '../../resources/images/right.png';
import axios from 'axios';

export default function Perfil() {
  const [sair, setSair] = useState(false);
  const [user, setUser] = useState({});
  
  const logout = () => {
    localStorage.setItem('user', '{}');
    axios.defaults.headers.common['Authorization'] = null;
    window.location.href = "/";
    setSair(false);
  }

  const loadContent = async () => {
    const data = JSON.parse(localStorage.getItem('user'))
    setUser(data);
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <div className="content perfil">
      <Link to="/perfil/editar">
        <div className="perfilUser">
          <div className="imageArea">
            <div className="menuPerfilArea">
              <div className="menuPerfil" style={{backgroundImage: `url(${user.image ? user.image : PerfilUser})`}}>
              </div>
            </div>
            <div className="infoUser">
              <div className="nome">
                {user.name}
              </div>
              <div className="edit">
                Editar perfil
              </div>
            </div>
          </div>
          <div className="edit">
            <img src={rightWhite} alt="editar" />
          </div>
        </div>
      </Link>
      {/* <Link to="/notificacoes">
        <div className="boxWhiteArea">
          <div className="itemTitle">
            Notificações
          </div>
          <div className="rightItem">
            <img src={right} alt="visualizar" />
          </div>
        </div>
      </Link> */}
      {/* <Link to="/carrinho/cupom">
        <div className="boxWhiteArea">
          <div className="itemTitle">
            Cupons
          </div>
          <div className="rightItem">
            <img src={right} alt="visualizar" />
          </div>
        </div>
      </Link> */}
      <Link to="/perfil/cartao">
        <div className="boxWhiteArea">
          <div className="itemTitle">
            Pagamentos
          </div>
          <div className="rightItem">
            <img src={right} alt="visualizar" />
          </div>
        </div>
      </Link>
      <Link to="/pedidos">
        <div className="boxWhiteArea">
          <div className="itemTitle">
            Pedidos
          </div>
          <div className="rightItem">
            <img src={right} alt="visualizar" />
          </div>
        </div>
      </Link>
      <Link to="/">
        <div className="boxWhiteArea">
          <div className="itemTitle">
            Escanear Qrcode
          </div>
          <div className="rightItem">
            <img src={right} alt="visualizar" />
          </div>
        </div>
      </Link>
      <div className="boxWhiteArea" onClick={() => setSair(true)}>
        <div className="itemTitle">
          Sair
        </div>
      </div>
      <div className={sair === true ? 'sairApp' : 'sairApp hide'}>
        <div className="sairBoxCenter">
          <div className="sairBoxArea">
            <div className="sairText">
              Tem certeza que deseja sair?
            </div>
          </div>
          <button type="button" className="sairNao" onClick={() => setSair(false)}>
            Não
          </button>
          <button type="button" className="sairSim" onClick={() => logout()}>
            Sim
          </button>
        </div>
      </div>
    </div>
  )
}
