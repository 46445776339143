import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import Search from '../../resources/images/menu/search.png';
import axios from 'axios';

export default function Pedidos() {
  const [pedidos, setPedidos] = useState(false);

  const getPedidos = async () => {
    let {data} = await axios.get('order/cart-list');
    setPedidos(data);
  }
  const loadContent = async () => {
    await getPedidos();
  };
  
  useEffect(() => {
    loadContent();
  }, []);
  return (
    <div className="content pedidos">
      {/* <div className="pedidosSearch">
        <form>
          <input required type="search" placeholder="Pesquise aqui..." />
          <button type="submit">
            <img src={Search} alt="busca" />
          </button>
        </form>
      </div>    */}
      <div className="pedidosContent">
        {/* <div className="title">
          Hoje
        </div> */}
        <ul>
        {pedidos ? pedidos.map((item, index) => (
          <li key={index}>
            <Link to={'/pedidos/interna?id='+item.id_customer_order+'&shopkeeper='+item.fk_id_shopkeeper} className="listaPedidosItem">
              <div className="info">
                {item.name}
              </div>
              <div className="codigo">
                {item.id_customer_order}
              </div>
              <div className="valor">
                <div className="valorTotal">
                  {item.customer_order_value ? (
                    <span>
                      R$ {item.customer_order_value.replace('.',',')}
                    </span>
                  ) : (
                    <span>--</span>
                  )}
                </div>
                <div className="hora">
                  {item.data}
                </div>
              </div>
            </Link>
          </li>
        )) : (
          <div className="title">
            Nenhum pedido encontrado.
          </div>
        )}
        </ul>
      </div> 
    </div>
  )
}
