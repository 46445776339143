import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import close from '../../resources/images/close.png';
import right from '../../resources/images/rightWhite.png';
import more from '../../resources/images/more.png';
import less from '../../resources/images/less.png';
import logo from '../../resources/images/logotipo-preto.png';
import Axios from 'axios';

export default function Carrinho() {
  const history = useHistory();
  const [produtos, setProdutos] = useState([]);
  const [carregar, setCarregar] = useState(null);
  const [order, setOrder] = useState(null);
  const [totalPedido, setTotalPedido] = useState(null);
  const [itensPedido, setItensPedido] = useState(null);
  const [observations, setObservations] = useState([]);

  const diminuiQuantidade = async (quantity, item) => {
    if(quantity <= 0) {
      return false
    }
    setCarregar(true);
    let array = produtos;
    array[item].amount = parseInt(array[item].amount)-1;
     try {
      let atualizar = {
        id_stock_list_product: array[item].id_stock_list_product,
        shopkeeper: localStorage.getItem('shopkeeper'),
        mv_shopkeeper: localStorage.getItem('mv_shopkeeper'),
        action: 2,
        amount: 1
      }
      let {data} = await Axios.post('order/cart', atualizar);
      console.log(data);
      let storeId = getParamFromUrl('shopkeeper');
      if (storeId) {
        //função verifica se há algum pedido em aberto
        await atualizaPedidoStatus(storeId);
      }
      // window.location.href = `/carrinho?shopkeeper=`+localStorage.getItem('shopkeeper');
      history.push(`/carrinho?shopkeeper=`+localStorage.getItem('shopkeeper'));
      setCarregar(false);
    } catch (err) {
      setCarregar(false);
      alert('Ops! '+err);
      console.log(err);
    }
  };
  const aumentaQuantidade = async (quantity, item) => {
    setCarregar(true);
    let array = produtos;
    if(quantity <= array[item].amount) {
      alert('Você não pode pedir mais que '+quantity+' itens')
      setCarregar(false);
      return true
    }
    array[item].amount = parseInt(array[item].amount)+1;
    try {
      let atualizar = {
        id_stock_list_product: array[item].id_stock_list_product,
        shopkeeper: localStorage.getItem('shopkeeper'),
        mv_shopkeeper: localStorage.getItem('mv_shopkeeper'),
        action: 1,
        amount: 1
      }
      let {data} = await Axios.post('order/cart', atualizar);
      console.log(data);
      setCarregar(false);

      let storeId = getParamFromUrl('shopkeeper');
      if (storeId) {
        //função verifica se há algum pedido em aberto
        atualizaPedidoStatus(storeId);
      }
      // window.location.href = `/carrinho?shopkeeper=`+localStorage.getItem('shopkeeper');
      history.push(`/carrinho?shopkeeper=`+localStorage.getItem('shopkeeper'));
    } catch (err) {
      setCarregar(false);
      alert('Ops! '+err);
      console.log(err);
    }
  };
  
  const getParamFromUrl = (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };
  const atualizaPedidoStatus = async (item) => {
    try {
      let {data} = await Axios.get('order/cart-open?shopkeeper='+item);
      setProdutos(data.items);
      setTotalPedido(data.total_price_br);
      setItensPedido(data.total_itens);
    } catch (err) {
      setCarregar(false);
      alert('Ops! Você não possui mais nenhum item no carrinho!');
      window.location.href = `/loja/detalhes?qrcode=${localStorage.getItem('code_qr')}&id_mv_shopkeepers=${localStorage.getItem('mv_shopkeeper')}`;
      console.log(err);
    }
  }

  const getPedido = async (item) => {
    let {data} = await Axios.get('order/cart-open?shopkeeper='+item);
    setProdutos(data.items);
    setTotalPedido(data.total_price_br);
    setItensPedido(data.total_itens);
    setObservations(data.observations);
    setOrder(data.order_id);
  }

  const removeItem = async (a) => {
    try {
      let atualizar = {
        id_observation: a.id,
        text_observation: a.observation,
      }
      let {data} = await Axios.post('order/observation-delete', atualizar);
      console.log(data);
      setCarregar(false);
      window.location.href = `/carrinho?shopkeeper=`+localStorage.getItem('shopkeeper');
      // history.push(`/carrinho?shopkeeper=`+localStorage.getItem('shopkeeper'));
    } catch (err) {
      setCarregar(false);
      alert('Ops! '+err);
      console.log(err);
    }
  }

  const finalizaPedido = async () => {
    try {
      localStorage.setItem('pedido_id', order);
      history.push("/carrinho/cartao");
      // window.location.href = "/carrinho/cartao";
    } catch (err) {
      alert('Ops, '+err);
      console.log(err);
    }
  }

  const loadContent = async () => {
    try {
      const storeId = getParamFromUrl('shopkeeper');
      if (storeId) {
        //função verifica se há algum pedido em aberto
        getPedido(storeId);
      }
    } catch(err) {
      console.log(err);
      console.log(err.response);
    }
  }
  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
  }, [produtos, itensPedido, totalPedido]);
  console.log(produtos);

  return (
    <div className="content carrinho">
      {observations && observations.length >= 1 ? (
        <div className="title title_bold">
          Observações
        </div>
      ) : (null)}
      <div className="observacoes">
        <ul>
          {observations && observations.map((item, index) => (
          <li key={index}>
            <div className="texto">
              {item.observation}
            </div>
            <div className="button" onClick={() => removeItem(item)}>
              <img src={close} alt="Remover" />
            </div>
          </li>
          ))}
        </ul>
      </div>
      {/* <Link to={'/carrinho/cupom'} className="cupomArea">
        <div className="cuponsTitle">
          <img src={cupom} alt="cupom" />
          Cupom
        </div>
        <div className="quantiodadeCupom">
          2
        </div>
      </Link> */}
      {produtos && produtos.length >= 1 ? (
        <div className="title title_bold">
          Pedido
        </div>
      ) : (
        <div className="title title_bold">
          Nenhum produto adicionado :(
        </div>
      )}
      <div className="pedidoListaArea">
        <ul>
        {produtos.map((prod, index) => (
          <li key={index}>
            <div className="photoarea">
              <img src={ prod.image ? prod.image : logo} alt="produto" />
            </div>
            <div className="productInfo">
              <div className="productName">
                {prod.name}
              </div>
              <div className="moreLessArea">
                <div className="somaDiminui">
                  <button className="less" onClick={() => diminuiQuantidade(prod.amount, index)} disabled={carregar}>
                    <img src={less} alt="menos" />
                  </button>
                  <div className="quantidadeMorearea">
                    <div className="quantidade">
                      {prod.amount}
                    </div>
                    <button className="more" onClick={() => aumentaQuantidade(prod.quantity, index)} disabled={carregar}>
                      <img src={more} alt="mais" />
                      Mais
                    </button>
                  </div>
                </div>
                <div className="valorProduto">
                  R$ {prod.total_price_br}
                </div>
              </div>
            </div>
          </li>
        ))}
        </ul>
      </div>
      {totalPedido && (
      <div className="areaSelected">
        {/* estas divs abaixo deverão aparecer após o usuário selecionar algum item */}
        <div className="spaceFixed"></div>
          <div className="areaSelectedBox" onClick={() => finalizaPedido()}>
            <div className="quantArea">
              {itensPedido}
            </div>
            <div className="valueArea">
              R$ {totalPedido}
            </div>
            <div className="nextArea">
              <img src={right} alt="avançar" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}