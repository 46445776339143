import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link, useHistory } from 'react-router-dom';
// import checked from '../../resources/images/check.png';
import logo from '../../resources/images/logotipo-preto.png';
import right from '../../resources/images/rightWhite.png';
import Axios from 'axios';

export default function LojaHome() {
  const [categories, setCategories] = useState([]);
  const [totalPedido, setTotalPedido] = useState(null);
  const [itensPedido, setItensPedido] = useState(null);
  const history = useHistory();
  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 45
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 45
    }
  };
  const responsiveProduct = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      partialVisibilityGutter: 6
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      partialVisibilityGutter: 6
    }
  };
  // const responsiveBebida = {
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //     partialVisibilityGutter: 80
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //     partialVisibilityGutter: 80
  //   }
  // };

  const getParamFromUrl = (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };

  const getPedido = async (item) => {
    if(localStorage.getItem('user') !== '{}' && localStorage.getItem('user') !== '' && localStorage.getItem('user') !== null) {
      let {data} = await Axios.get('order/cart-open?shopkeeper='+item);
      console.log(data.items, 'pedido carrinho');
      if(data) {
        setTotalPedido(data.total_price_br);
        setItensPedido(data.total_itens)
      }
    }
  }
  const getPageUrl = async (a,id) => {
    // window.location.href = '/loja/produtos?qrcode=$'+getParamFromUrl('qrcode')+'&id_mv_shopkeepers='+getParamFromUrl('id_mv_shopkeepers')+'&category_id='+id+'&category_name='+a;
    history.push('/loja/produtos?qrcode=$'+getParamFromUrl('qrcode')+'&id_mv_shopkeepers='+getParamFromUrl('id_mv_shopkeepers')+'&category_id='+id+'&category_name='+a)
  }
  const loadContent = async () => {
    try {
      const qrcode = getParamFromUrl('qrcode');
      const storeId = getParamFromUrl('id_mv_shopkeepers');

      let url = `webapp/list-products?qrcode=${qrcode}`;
      
      let user = JSON.parse(localStorage.getItem('user'));
      if(user) {
        Axios.defaults.headers.common['Authorization'] = user.api_token;
      }

      if (storeId) {
        url += `&id_mv_shopkeepers=${storeId}`;
      }
      let shop = localStorage.getItem('shopkeeper');
      localStorage.setItem('mv_shopkeeper', storeId);
      //função verifica se há algum pedido em aberto
      if(shop) {
        getPedido(shop);
      }

      const { data } = await Axios.get(url);
      //se for do tipo mv não tem pedido. se for shopkeeper, tem pedido
      let shopeek = localStorage.getItem('shopkeeper');
      if(!shopeek) {
        localStorage.setItem('shopkeeper', data.shopkeeper.fk_id_shopkeepers);
      }
      if(data.products.category != undefined) {
        setCategories(data.products.category);
      } else {
        setCategories(false);
      }
      console.log(data.products.category);
    } catch(err) {
      console.log(err);
      console.log(err.response);
    }
  }
  useEffect(() => {
    loadContent();
  }, []);
  return (
    <div className="content lojasArea">
      {categories && categories.map((item, index) =>
        <div key={index}>
          { index == 0 || index == 2 ?
            //   <div className="divideTitle">
            //     <div className="homeTitle">
            //       {item.name}
            //     </div>
            //     <div className="verTodos" onClick={() => getPageUrl(item.name, item.id)}>
            //       {/* <Link to={`/loja/produtos?qrcode=${getParamFromUrl('qrcode')}&id_mv_shopkeepers=${getParamFromUrl('id_mv_shopkeepers')}&category_id=${item.id}&category_name=${item.name}`}> */}
            //         Ver todos
            //       {/* </Link> */}
            //     </div>
            //   </div>
            //   <div className="bebidasCarousel">
            //     <Carousel
            //       swipeable={true}
            //       draggable={true}
            //       arrows={false}
            //       showDots={false}
            //       responsive={responsiveBebida}
            //       ssr={true} // means to render carousel on server-side.
            //       partialVisible={true}
            //       infinite={true}
            //       autoPlay={false}
            //       autoPlaySpeed={1000}
            //       keyBoardControl={true}
            //       customTransition="transform 300ms ease-in-out"
            //       transitionDuration={500}
            //       containerClass="carousel-container"
            //       removeArrowOnDeviceType={["tablet", "mobile"]}
            //       deviceType={"mobile"}
            //       dotListClass="custom-dot-list-style"
            //       itemClass="carousel-space"
            //     >
            //       {item.products.map((product, index) => (
            //         product.quantity > 0 && (
            //         <Link key={index} to={`/loja/add?&product_id=${product.id}`} className="relativeItem">
            //           <div className="imageBig">
            //             <div className="imageBigItem" style={{backgroundImage: "url(" + (product.banner ? product.banner : product.image) + ")"}}></div>
            //           </div>
            //           {/* div abaixo serve para mostrar produtos selecionados  */}
            //           {/* <div className="iconSelected">
            //             <img src={checked} alt="adicionado" />
            //           </div> */}
            //         </Link>
            //       )))}
            //     </Carousel>
            //   </div>  
            // </div>
            <div className="bebidasArea">
              <div className="divideTitle">
                <div className="homeTitle">
                  {item.name}
                </div>
                <div className="verTodos" onClick={() => getPageUrl(item.name, item.id)}>
                  Ver todos
                </div>
              </div>
              <div className="carouselDestaque">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  arrows={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  partialVisible={true}
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform 300ms ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  deviceType={"mobile"}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-space"
                >
                  {item.products.map((product, index) => (
                    product.quantity > 0 && (
                      <Link key={index} to={`/loja/add?&product_id=${product.id}`} className="relativeItem">
                      <div className="ImageCarousel">
                        <div className="borderCarousel">
                          <div className="image" style={{backgroundImage: "url(" + (product.banner ? product.banner : product.image) + ")"}}></div>
                          <div className="info">
                            <div className="infoDados">
                              <div className="productName">{product.name}</div>
                              <div className="productValue">R$ {product.price_br}</div>
                              {product.descricao !== null && (
                                <div className="lead">
                                  {product.descricao}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* div abaixo serve para mostrar produtos selecionados */}
                        {/* <div className="iconSelected">
                          <img src={checked} alt="adicionado" />
                        </div> */}
                      </div>
                    </Link>
                  )))}
                </Carousel>
              </div>
            </div> 
          :
            <div className="lanchesArea">
              <div className="divideTitle">
                <div className="homeTitle">
                  {item.name}
                </div>
                <div className="verTodos" onClick={() => getPageUrl(item.name, item.id)}>
                    Ver todos
                </div>
              </div>
              <div className="lanchesAreaCarousel">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  arrows={false}
                  showDots={false}
                  responsive={responsiveProduct}
                  ssr={true} // means to render carousel on server-side.
                  partialVisible={true}
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform 300ms ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  deviceType={"mobile"}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-space"
                >
                  {item.products.map((product, index) => (
                    product.quantity > 0 && (
                    <Link key={index} to={`/loja/add?&product_id=${product.id}`} className="relativeItem">
                      <div className="boxCarousel">
                        <div className="image" style={{backgroundImage: "url(" + (product.image ? product.image : logo) + ")"}}></div>
                      </div>
                      <div className="nome">
                        {product.name}
                      </div>
                      <div className="valor">
                        R$ {product.price_br}
                      </div>
                      {/* div abaixo serve para mostrar produtos selecionados  */}
                      {/* <div className="iconSelected">
                        <img src={checked} alt="adicionado" />
                      </div> */}
                    </Link>
                  )))}
                </Carousel>
              </div>
            </div>
          }
        </div>
      )}

      {/* estas divs abaixo deverão aparecer após o usuário selecionar algum item */}
      {totalPedido && (
        <>
        <div className="spaceFixed"></div>
        <Link className="areaSelected" to={'/carrinho?shopkeeper='+localStorage.getItem('shopkeeper')}>
          <div className="areaSelectedBox">
            <div className="quantArea">
              {itensPedido}
            </div>
            <div className="valueArea">
              R$ {totalPedido}
            </div>
            <div className="nextArea">
              <img src={right} alt="avançar" />
            </div>
          </div>
        </Link>
        </>
      )}
      {categories == false && (
        <div className="title_bold">
          Ops, nenhum produto encontrado.
        </div>
      )}
    </div>
  )
}
